"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.DropdownListener = void 0;
var Dropdown_1 = require("./Dropdown");
var DropdownHandler_1 = require("./DropdownHandler");
/**
 * @class
 * @extends {DropdownHandler<T>}
 **/
var DropdownListener = /** @class */ (function (_super) {
    __extends(DropdownListener, _super);
    function DropdownListener(trigger) {
        var _this = _super.call(this) || this;
        _this.trigger = trigger;
        _this.dropdown = new Dropdown_1.Dropdown(_this.trigger);
        return _this;
    }
    DropdownListener.prototype.listen = function () {
        this.dropdown.trigger.addEventListener("click", this.handler.bind(this.dropdown, this));
    };
    return DropdownListener;
}(DropdownHandler_1.DropdownHandler));
exports.DropdownListener = DropdownListener;
