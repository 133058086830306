"use strict";
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Dropdown = void 0;
var constants_1 = require("../constants");
var dropdownConstants_1 = require("../constants/dropdownConstants");
var Dropdown = /** @class */ (function () {
    function Dropdown(_element) {
        var _this = this;
        this._element = _element;
        this._target = new Set();
        this.ariaControls &&
            this.ariaControls.split(/\s/).forEach(function (control) { return _this._target.add(document.getElementById(control)); });
        this.targetClassElement && __spreadArray([], __read(this.targetClassElement), false).forEach(function (element) { return _this._target.add(element); });
    }
    Object.defineProperty(Dropdown.prototype, "parent", {
        get: function () {
            return this._element.closest(constants_1.PERFIX.CLASS_NAME + dropdownConstants_1.CLASS_NAMES.PARENT);
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(Dropdown.prototype, "targetClassElement", {
        get: function () {
            return this.parent && this.parent.getElementsByClassName(dropdownConstants_1.CLASS_NAMES.TARGET);
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(Dropdown.prototype, "trigger", {
        get: function () {
            return this._element;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(Dropdown.prototype, "ariaControls", {
        get: function () {
            return this._element.getAttribute("aria-controls");
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(Dropdown.prototype, "targets", {
        get: function () {
            return this._target;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(Dropdown.prototype, "dataLabel", {
        get: function () {
            var child = this._element.querySelector("[data-label]");
            return this._element.getAttribute("data-label") || (child && child.getAttribute("data-label"));
        },
        enumerable: false,
        configurable: true
    });
    return Dropdown;
}());
exports.Dropdown = Dropdown;
