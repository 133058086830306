"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ComboBoxEvents = void 0;
var constants_1 = require("../../constants");
var constants_2 = require("./config/constants");
var ConvertToHalfWidth_1 = require("../ConvertToHalfWidth");
var IsIncluded_1 = require("../IsIncluded");
/**
 * ComboBoxEvents コンボボックスのイベントを登録する
 **/
var ComboBoxEvents = /** @class */ (function () {
    function ComboBoxEvents() {
        var _this = this;
        this.resultsCount = 0;
        /**
         * @function targetCollectionHandler 対象のinputがchangeした時にAND検索し、表示・非表示する
         * @param  {Event}  e  DOM で発生するイベント
         * @returns {void} 検索結果に応じてターゲットを表示・非表示にする。
         **/
        this.targetCollectionHandler = function (e) {
            e.currentTarget instanceof HTMLInputElement && e.currentTarget.hasAttribute("aria-owns") !== false
                ? (_this.ownsId = e.currentTarget.getAttribute("aria-owns"))
                : "";
            _this.targetCollection = document.querySelectorAll([constants_1.ELEMENT_ID_PREFIX, _this.ownsId, " ", constants_1.ELEMENT_CLASS_PREFIX, constants_2.COMBOBOX_CLASS_NAMES.TARGET].join(""));
            _this.resultsElement = document.querySelector([constants_1.ELEMENT_ID_PREFIX, _this.ownsId, constants_1.ELEMENT_CLASS_PREFIX, constants_2.COMBOBOX_CLASS_NAMES.RESULT_LIST].join(""));
            _this.setValueElement = document.querySelector([constants_1.ELEMENT_ID_PREFIX, _this.ownsId, constants_1.ELEMENT_CLASS_PREFIX, constants_2.COMBOBOX_CLASS_NAMES.SET_VALUE].join(""));
            if (_this.setValueElement == null) {
                _this.filterTarget(e, _this.targetCollection);
            }
            else {
                _this.setDataValue(e, _this.setValueElement);
            }
        };
        /**
         * @function putResultLength 結果をinnerTextで返す
         * @param  {number}  count  検索結果件数
         * @returns {void}
         **/
        this.putResultLength = function (count) {
            var regex = /[^0-9]/g;
            if (_this.alertElement == undefined || _this.alertText == undefined)
                return;
            _this.alertElement.innerText = _this.alertText.replace(_this.alertText.replace(regex, ""), count.toString());
        };
        /**
         * 対象のdatalisit内にあるoptionのdata-valueを対象のinputのvalueに出力する
         */
        this.setDataValue = function (e, target) {
            _this.datalist = document.querySelector([constants_1.ELEMENT_ID_PREFIX, _this.ownsId, constants_1.ELEMENT_CLASS_PREFIX, constants_2.COMBOBOX_CLASS_NAMES.DATALIST].join(""));
            _this.datalist && target
                ? Array.from(_this.datalist.children, function (child) {
                    var dataValue;
                    if (!(child instanceof HTMLOptionElement) ||
                        child.hasAttribute("data-value") !== true ||
                        child.getAttribute("data-value") == null) {
                        return;
                    }
                    else if (child.hasAttribute("data-value") !== false) {
                        dataValue = child.getAttribute("data-value");
                        if (e.currentTarget instanceof HTMLInputElement && e.currentTarget.value == child.value) {
                            dataValue != null && target ? (target.value = dataValue) : "";
                        }
                    }
                })
                : "";
        };
        /**
         * ターゲットとなる配列の要素をフィルタリングし、表示・非表示にする
         */
        this.filterTarget = function (e, targetCollection) {
            var _a, _b;
            _this.alertElement = document.getElementById(constants_2.IDS.COUNT);
            _this.alertText = (_a = _this.alertElement) === null || _a === void 0 ? void 0 : _a.innerText;
            _this.resultList = [];
            var values = e.currentTarget instanceof HTMLInputElement
                ? Array.from(new Set(new ConvertToHalfWidth_1.ConvertToHalfInput().convert((_b = e.currentTarget) === null || _b === void 0 ? void 0 : _b.value).split(" ")))
                : [];
            targetCollection === null || targetCollection === void 0 ? void 0 : targetCollection.forEach(function (target) {
                var _a;
                var data = target.getAttribute("data-value");
                if (data !== null && new IsIncluded_1.IsIncluded().isAllIncluded(values, data) !== true) {
                    target.style.display = "none";
                    if (target.classList.contains(constants_1.STATUS_CLASS_NAMES.HIDDEN))
                        return;
                    target.classList.add(constants_1.STATUS_CLASS_NAMES.HIDDEN);
                }
                else {
                    target.removeAttribute("style");
                    (_a = _this.resultList) === null || _a === void 0 ? void 0 : _a.push(target);
                    if (!target.classList.contains(constants_1.STATUS_CLASS_NAMES.HIDDEN))
                        return;
                    target.classList.remove(constants_1.STATUS_CLASS_NAMES.HIDDEN);
                }
            });
            _this.putResultLength(_this.resultList.length);
        };
    }
    return ComboBoxEvents;
}());
exports.ComboBoxEvents = ComboBoxEvents;
