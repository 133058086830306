"use strict";
/**
 * 全角文字列の半角変換
 *  @param  strVal  String  チェック対象文字列
 *  @return String  変換後の文字列
 *
 **/
Object.defineProperty(exports, "__esModule", { value: true });
exports.ConvertToHalfInput = void 0;
var ConvertToHalfInput = /** @class */ (function () {
    function ConvertToHalfInput() {
        this.convert = function (string) {
            string.replace(/[！-～]/g, function (tmpStr) {
                // 文字コードをシフト
                return String.fromCharCode(tmpStr.charCodeAt(0) - 0xfee0);
            });
            // 文字コードシフトで対応できない文字の変換
            return string.replace(/”/g, '"').replace(/’/g, "'").replace(/‘/g, '`').replace(/￥/g, '\\').replace(/　/g, ' ').replace(/〜/g, '~');
        };
    }
    return ConvertToHalfInput;
}());
exports.ConvertToHalfInput = ConvertToHalfInput;
