"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isAriaAttributeTrue = void 0;
/**
 * aria属性が"true"か、"false"| nullか
 * @param {string|null} ariaAttribute
 * @returns {boolean}
 */
function isAriaAttributeTrue(ariaAttribute) {
    return ariaAttribute === "true";
}
exports.isAriaAttributeTrue = isAriaAttributeTrue;
