"use strict";
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.DialogHandler = void 0;
var constants_1 = require("../../constants");
var OverlayHandler_1 = require("../OverlayHandler");
var scrollLockFix_1 = require("../scrollLockFix");
/**
 * @class
 */
var DialogHandler = exports.DialogHandler = /** @class */ (function () {
    function DialogHandler(trigger) {
        var _a, _b;
        var _this = this;
        var _c, _d;
        this.closableElements = [];
        /**
         * @property { (HTMLElement | null)[]} hiddenElements target[aria-hidden="false"]のときにaria-hidden='true'にする要素
         */
        this.hiddenElements = [];
        this.handler = function (e) {
            if (_this.trigger instanceof HTMLElement && _this.trigger.getAttribute("aria-expanded") === "true") {
                _this.close();
            }
            else {
                _this.open();
            }
        };
        /**
         * @method open モーダルを開く
         * @returns {void}
         */
        this.open = function () {
            var _a;
            _this.scrollLock.listen();
            if (!_this.trigger)
                return;
            _this.trigger.ariaExpanded = "true";
            _this.body.classList.add(constants_1.STATUS_CLASS_NAMES.SCROLL_LOCK);
            if (!_this.target)
                return;
            _this.target.ariaHidden = "false";
            _this.target.classList.add(constants_1.STATUS_CLASS_NAMES.SHOWN);
            _this.target instanceof HTMLDialogElement && _this.target.show();
            if (_this.closeButton) {
                _this.closeButton.ariaExpanded = "true";
            }
            if (_this.cancelButton) {
                _this.cancelButton.ariaExpanded = "true";
            }
            new OverlayHandler_1.OverlayHandler().showOverlay(_this.body, _this.overlay);
            (_a = _this.hiddenElements) === null || _a === void 0 ? void 0 : _a.forEach(function (element) { return (element ? element.setAttribute("aria-hidden", "true") : null); }); // WAI-ARIAの設定を戻す
            var focusableElements = __spreadArray([], __read(_this.target.querySelectorAll(DialogHandler.FOCUSABLE_ELEMENTS.join(","))), false);
            if (focusableElements.length < 1)
                return;
            var activeElement = document.activeElement;
            if (activeElement instanceof HTMLElement) {
                activeElement.blur();
            }
            focusableElements[0].focus();
        };
        /**
         * @method close モーダルを閉じる
         * @returns {void}
         */
        this.close = function () {
            var _a;
            _this.scrollLock.unlisten();
            if (_this.trigger) {
                _this.trigger.ariaExpanded = "false";
                _this.body.classList.remove(constants_1.STATUS_CLASS_NAMES.SCROLL_LOCK);
            }
            if (_this.target) {
                _this.target.ariaHidden = "true";
                _this.target.classList.remove(constants_1.STATUS_CLASS_NAMES.SHOWN);
                _this.target instanceof HTMLDialogElement && _this.target.close();
            }
            if (_this.closeButton) {
                _this.closeButton.ariaExpanded = "false";
            }
            if (_this.cancelButton) {
                _this.cancelButton.ariaExpanded = "false";
            }
            _this.trigger instanceof HTMLElement && _this.trigger.focus(); // フォーカス位置を戻す
            (_a = _this.hiddenElements) === null || _a === void 0 ? void 0 : _a.forEach(function (element) { return element === null || element === void 0 ? void 0 : element.removeAttribute("aria-hidden"); }); // WAI-ARIAの設定を戻す
            new OverlayHandler_1.OverlayHandler().removeOverlay(_this.body, _this.overlay);
            document.removeEventListener("keydown", _this.keyboardHandler, { capture: true });
        };
        /**
         * @method keyboardHandler Escキーで閉じる
         * @param {KeyboardEvent} e
         */
        this.keyboardHandler = function (e) {
            if (e.key !== "Esc" && e.key !== "Escape")
                return;
            _this.close();
        };
        this.trigger = trigger;
        this.controls =
            ((_c = this.trigger.getAttribute("aria-controls")) === null || _c === void 0 ? void 0 : _c.split(/\s/)) ||
                ((_d = this.trigger.getAttribute("aria-own")) === null || _d === void 0 ? void 0 : _d.split(/\s/)) ||
                [];
        this.target = document.querySelector(constants_1.PERFIX.TAG_NAME + this.controls + constants_1.PERFIX.CLASS_NAME + DialogHandler.CLASS_NAMES.DIALOG);
        this.body = document.body;
        this.cancelButton =
            document.querySelector(constants_1.PERFIX.CLASS_NAME +
                DialogHandler.CLASS_NAMES.CANCEL_BUTTON +
                "[aria-controls=\"".concat(this.controls.find(function (string) { return string.includes("dialog"); }) || this.controls[0], "\"]")) || document.querySelector(constants_1.PERFIX.CLASS_NAME + DialogHandler.CLASS_NAMES.CANCEL_BUTTON);
        this.closeButton =
            document.querySelector(constants_1.PERFIX.CLASS_NAME +
                DialogHandler.CLASS_NAMES.CLOSE_BUTTON +
                "[aria-controls=\"".concat(this.controls.find(function (string) { return string.includes("dialog"); }) || this.controls[0], "\"]")) || document.querySelector(constants_1.ELEMENT_CLASS_PREFIX + DialogHandler.CLASS_NAMES.CLOSE_BUTTON);
        this.overlay = document.querySelector([constants_1.PERFIX.CLASS_NAME, DialogHandler.CLASS_NAMES.OVERLAY].join(""));
        this.header = document.getElementsByTagName("header");
        this.nav = document.querySelector([constants_1.PERFIX.CLASS_NAME, DialogHandler.CLASS_NAMES.NAV].join(""));
        this.footer = document.getElementsByTagName("footer");
        if (this.body) {
            this.hiddenElements.push(this.body);
        }
        if (this.header) {
            (_a = this.hiddenElements).push.apply(_a, __spreadArray([], __read(this.header), false));
        }
        if (this.footer) {
            (_b = this.hiddenElements).push.apply(_b, __spreadArray([], __read(this.footer), false));
        }
        if (this.nav) {
            this.hiddenElements.push(this.nav);
        }
        if (this.closeButton) {
            this.closableElements.push(this.closeButton);
        }
        if (this.cancelButton) {
            this.closableElements.push(this.cancelButton);
        }
        if (this.overlay) {
            this.closableElements.push(this.overlay);
        }
        this.scrollLock = new scrollLockFix_1.ScrollLockFix(this.target);
    }
    DialogHandler.CLASS_NAMES = {
        BODY: "js-body",
        HEADER: "js-header",
        FOOTER: "js-footer",
        NAV: "js-nav",
        OVERLAY: "js-overlay",
        DIALOG: "js-dialog",
        OPEN_BUTTON: "js-dialogOpenButton",
        CLOSE_BUTTON: "js-closeButton",
        CANCEL_BUTTON: "js-dialogCancel",
        CAN_SCROLL: "is-scrollable",
    };
    DialogHandler.FOCUSABLE_ELEMENTS = [
        "a[href]",
        "area[href]",
        'input:not([disabled]):not([type="hidden"]):not([aria-hidden])',
        "select:not([disabled]):not([aria-hidden])",
        "textarea:not([disabled]):not([aria-hidden])",
        "button:not([disabled]):not([aria-hidden])",
        "iframe",
        "object",
        "embed",
        "[contenteditable]",
        '[tabindex]:not([tabindex^="-"])',
    ];
    return DialogHandler;
}());
