"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.IsIncluded = void 0;
var IsIncluded = /** @class */ (function () {
    function IsIncluded() {
        /**
         * @function 配列の中にすべて含まれているか
         * @param {string[]} array 検索対象を格納した配列
         * @param {string}  target 検索ワード
         * @returns  {boolean}  結果
         */
        this.isAllIncluded = function (array, target) {
            return array.every(function (index) { return target.includes(index); });
        };
    }
    return IsIncluded;
}());
exports.IsIncluded = IsIncluded;
