"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.ComboBoxListener = void 0;
var constants_1 = require("../../constants");
var constants_2 = require("./config/constants");
var ComboBoxEvents_1 = require("./ComboBoxEvents");
/**
 * ComboBoxListener
 * ComboBoxEventsで定義した関数を処理する。
 **/
var ComboBoxListener = /** @class */ (function (_super) {
    __extends(ComboBoxListener, _super);
    function ComboBoxListener() {
        var _this = _super.call(this) || this;
        /**
         * DOMContentLoaded後に実行するイベント
         * 各コンボボックスのinputのイベントリスナーを登録する
         **/
        _this.event = function () {
            var _a;
            _this.parentCollection = document.querySelectorAll([constants_1.ELEMENT_CLASS_PREFIX, constants_2.COMBOBOX_CLASS_NAMES.PARENT].join(""));
            (_a = _this.parentCollection) === null || _a === void 0 ? void 0 : _a.forEach(function (target) {
                var _a;
                _this.input = Array.from(target.children).find(function (child) {
                    return child.classList.contains(constants_2.COMBOBOX_CLASS_NAMES.INPUT);
                });
                (_a = _this.input) === null || _a === void 0 ? void 0 : _a.addEventListener("change", function (e) {
                    if (e.cancelable) {
                        e.stopPropagation();
                    }
                    _this.targetCollectionHandler(e);
                });
            });
        };
        var self = _this;
        window.addEventListener("DOMContentLoaded", function (e) {
            self.event();
        });
        return _this;
    }
    return ComboBoxListener;
}(ComboBoxEvents_1.ComboBoxEvents));
exports.ComboBoxListener = ComboBoxListener;
