"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.OverlayHandler = void 0;
var constants_1 = require("../constants");
var overlayConstants_1 = require("../constants/overlayConstants");
var OverlayHandler = /** @class */ (function () {
    function OverlayHandler() {
        var _this = this;
        this.isShown = false;
        /**
         * @function オーバーレイを表示
         * @param {HTMLElement | null | undefined} target 対象となる要素
         **/
        this.showOverlay = function (parent, overlay) {
            if (_this.isShown === true && parent != undefined)
                return;
            if (parent != undefined && parent.classList.contains(overlayConstants_1.OVERLAY_CLASS_NAMES.OVERLAY) !== true) {
                parent.classList.add(overlayConstants_1.OVERLAY_CLASS_NAMES.OVERLAY);
            }
            overlay != null ? (overlay.style.zIndex = "100") : "";
            _this.isShown = true;
        };
        /**
         * @function オーバーレイを非表示
         * @param {HTMLElement | null | undefined} target 対象となる要素
         **/
        this.removeOverlay = function (parent, overlay) {
            if (!_this.isShown !== true && parent != undefined)
                return;
            overlay === null || overlay === void 0 ? void 0 : overlay.removeAttribute("style");
            if (parent != undefined &&
                parent.classList.contains(overlayConstants_1.OVERLAY_CLASS_NAMES.OVERLAY) &&
                document.querySelectorAll([constants_1.ELEMENT_CLASS_PREFIX, constants_1.STATUS_CLASS_NAMES.ACTIVE].join("")).length < 1) {
                parent.classList.remove(overlayConstants_1.OVERLAY_CLASS_NAMES.OVERLAY);
                _this.isShown = false;
            }
        };
    }
    return OverlayHandler;
}());
exports.OverlayHandler = OverlayHandler;
