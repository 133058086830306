"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SEPARATOR = exports.STATUS_CLASS_NAMES = exports.PERFIX = exports.ELEMENT_ID_PREFIX = exports.ELEMENT_CLASS_PREFIX = void 0;
/**
 * クラス
 * @const  string
 */
exports.ELEMENT_CLASS_PREFIX = ".";
/**
 * ID
 * @const  string
 */
exports.ELEMENT_ID_PREFIX = "#";
exports.PERFIX = {
    CLASS_NAME: ".",
    TAG_NAME: "#",
};
/**
 * スクリプト用汎用クラス名
 */
exports.STATUS_CLASS_NAMES = {
    ACTIVE: "is-active",
    SHOWN: "is-shown",
    HIDDEN: "is-hidden",
    CAN_SCROLL: "is-scrollable",
    SCROLL_LOCK: "is-scrollLock",
    REQUIRED: "is-required",
    PROCESSING: "is-processing",
};
/**
 * 郵便番号や電話番号のセパレーター
 * @const  string
 */
exports.SEPARATOR = "-";
