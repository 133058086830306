"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.InitMap = exports.MapsJavaScriptAPI = void 0;
var js_api_loader_1 = require("@googlemaps/js-api-loader");
var constants_1 = require("../constants");
var mapConstants_1 = require("../constants/mapConstants");
var MapsJavaScriptAPI = /** @class */ (function () {
    function MapsJavaScriptAPI(loaderOptions) {
        this.loaderOptions = {
            apiKey: mapConstants_1.API_KEY,
            version: "weekly",
            region: "JP",
            language: "ja",
            libraries: ["geometry"],
            // something additional options
        };
        /**
         * define the properties that can be set on a Map
         * @date 2024/2/8 - 13:22:06
         *
         * @type {google.maps.MapOptions}
         */
        this.mapOptions = {
            zoom: 14,
            mapTypeControl: false,
            streetViewControl: false,
            draggable: true,
        };
        this.markerOptions = {
            clickable: true,
            map: this.map,
            draggable: true,
        };
        this.loader = new js_api_loader_1.Loader(loaderOptions !== null && loaderOptions !== void 0 ? loaderOptions : this.loaderOptions);
    }
    MapsJavaScriptAPI.prototype.init = function () {
        this.geocoder = new google.maps.Geocoder();
    };
    return MapsJavaScriptAPI;
}());
exports.MapsJavaScriptAPI = MapsJavaScriptAPI;
var InitMap = /** @class */ (function () {
    function InitMap() {
        var _this = this;
        this.mapElement = document.querySelector(".js_map");
        this.clickPosition = function (mapsMouseEvent) {
            if (mapsMouseEvent.latLng == undefined)
                return;
            _this.reverseGeoCoding(mapsMouseEvent.latLng);
        };
        this.dragPin = function () {
            var _a;
            var position = (_a = _this.marker) === null || _a === void 0 ? void 0 : _a.getPosition();
            if (position == undefined)
                return;
            _this.reverseGeoCoding(position);
        };
        /**
         * @function convertToHalfInput 全角文字列の半角変換
         *  @param {String} strVal チェック対象文字列
         *  @return {String} 変換後の文字列
         *
         **/
        this.convertToHalfInput = function (strVal) {
            // 半角変換
            var halfVal = strVal.replace(/[！-～]/g, function (tmpStr) {
                // 文字コードをシフト
                return String.fromCharCode(tmpStr.charCodeAt(0) - 0xfee0);
            });
            // 文字コードシフトで対応できない文字の変換
            return halfVal
                .replace(/”/g, '"')
                .replace(/’/g, "'")
                .replace(/‘/g, "`")
                .replace(/￥/g, "\\")
                .replace(/　/g, " ")
                .replace(/〜/g, "~");
        };
        /**
         * @method getAddress 緯度経度にマップとマーカーを移動し、そこから住所を要素に返す
         *  @param {google.maps.GeocoderResult[] | null} results ジオコーディングレスポンス
         *  @param {google.maps.GeocoderStatus} status リクエスト結果のステータス
         *
         **/
        this.getAddress = function (results, status) {
            var _a, _b;
            if (status == google.maps.GeocoderStatus.OK && results) {
                var currentAddr02 = (_a = _this.addr02Element) === null || _a === void 0 ? void 0 : _a.value;
                if (!results[0].address_components)
                    return;
                var sublocality03_1 = "", sublocality04_1 = "", premise_1 = [], addr01_1 = [], addr02 = "";
                results[0].address_components.map(function (component, index, array) {
                    if (component == undefined)
                        return;
                    switch (true) {
                        case component.types.includes("premise"):
                            premise_1.push(component.long_name);
                            break;
                        case component.types.includes("sublocality_level_4"):
                            sublocality04_1 = component.long_name;
                            break;
                        case component.types.includes("sublocality_level_3"):
                            sublocality03_1 = component.long_name;
                            break;
                        case component.types.some(function (type) { return _this.addressPattern.Addr01.includes(type); }):
                            addr01_1.push(component.long_name);
                            break;
                        case component.types.includes("administrative_area_level_1"):
                            if (!_this.prefElement)
                                break;
                            Array.from(_this.prefElement.options, function (option) {
                                if (option.selected) {
                                    option.selected = false;
                                }
                                if (option.text === component.long_name) {
                                    option.selected = true;
                                }
                            });
                            break;
                        case component.types.includes("postal_code"):
                            if (!_this.postalCodeElement)
                                break;
                            _this.postalCodeElement.value = component.long_name.replace("-", "");
                            break;
                    }
                });
                if (premise_1.length > 0) {
                    if (((sublocality03_1.includes("丁") && !sublocality04_1.includes("番")) || sublocality03_1 == "") &&
                        sublocality04_1 != "") {
                        addr02 = [sublocality03_1, sublocality04_1, "-", premise_1.reverse().join(" ")].join("");
                    }
                    else {
                        addr02 = [sublocality03_1, sublocality04_1, premise_1.reverse().join(" ")].join("");
                    }
                }
                else {
                    addr02 = [sublocality03_1, sublocality04_1, premise_1.reverse().join(" ")].join("");
                }
                _this.addr01Element ? (_this.addr01Element.value = addr01_1.reverse().join("")) : "";
                _this.addr02Element &&
                    (((_b = _this.currrentAddress) === null || _b === void 0 ? void 0 : _b.addr02.includes(sublocality04_1)) !== true ||
                        premise_1.some(function (item) { var _a; return (_a = _this.currrentAddress) === null || _a === void 0 ? void 0 : _a.addr02.includes(item); }) !== true)
                    ? (_this.addr02Element.value = _this.convertToHalfInput(addr02))
                    : console.log(premise_1.some(function (item) { var _a; return (_a = _this.currrentAddress) === null || _a === void 0 ? void 0 : _a.addr02.includes(item); }));
            }
            else {
                return window.alert("Geocode was not successful for the following reason: " + status);
            }
        };
        /**
         * @method setAddress 緯度経度にマップとマーカーを移動し、そこから住所を返す
         *  @param  {google.maps.GeocoderResult[] | null} results ジオコーディングレスポンス
         *  @param  {google.maps.GeocoderStatus} status  リクエスト結果のステータス
         **/
        this.setAddress = function (results, status) {
            var _a, _b;
            if (status == google.maps.GeocoderStatus.OK && results) {
                if (!results[0].address_components)
                    return;
                var sublocality03_2 = "", sublocality04_2 = "", premise_2 = [], addr01_2 = [], addr02 = "";
                results[0].address_components.map(function (component, index, array) {
                    if (component == undefined)
                        return;
                    switch (true) {
                        case component.types.includes("premise") || component.types.includes("subpremise"):
                            premise_2.push(component.long_name);
                            break;
                        case component.types.includes("sublocality_level_4"):
                            sublocality04_2 = component.long_name;
                            break;
                        case component.types.includes("sublocality_level_3"):
                            sublocality03_2 = component.long_name;
                            break;
                        case component.types.some(function (type) { return _this.addressPattern.Addr01.includes(type); }):
                            addr01_2.push(component.long_name);
                            break;
                        case component.types.includes("administrative_area_level_1"):
                            if (!_this.prefElement)
                                return;
                            Array.from(_this.prefElement.options, function (option) {
                                if (option.selected) {
                                    option.selected = false;
                                }
                                if (option.text === component.long_name) {
                                    option.selected = true;
                                }
                            });
                            break;
                        /** postal_code */
                        case component.types.includes("postal_code"):
                            if (!_this.postalCodeElement)
                                return;
                            _this.postalCodeElement.value = component.long_name.replace("-", "");
                            break;
                    }
                });
                if (premise_2.length > 0) {
                    if ((sublocality03_2.includes("丁") || sublocality03_2 == "") && sublocality04_2 != "") {
                        addr02 = [sublocality03_2, sublocality04_2, "-", premise_2.reverse().join(" ")].join("");
                    }
                    else {
                        addr02 = [sublocality03_2, sublocality04_2, premise_2.reverse().join(" ")].join("");
                    }
                }
                else {
                    addr02 = [sublocality03_2, sublocality04_2, premise_2.reverse().join(" ")].join("");
                }
                /** addr01 */
                _this.addr01Element && (_this.addr01Element.value = addr01_2.reverse().join(""));
                /** addr02 */
                if (_this.addr02Element) {
                    ((_a = _this.currrentAddress) === null || _a === void 0 ? void 0 : _a.addr02.includes(sublocality04_2)) !== true ||
                        premise_2.some(function (item) { var _a; return (_a = _this.currrentAddress) === null || _a === void 0 ? void 0 : _a.addr02.includes(item); }) !== true
                        ? (_this.addr02Element.value = _this.convertToHalfInput(addr02))
                        : console.log((_b = _this.currrentAddress) === null || _b === void 0 ? void 0 : _b.addr02, addr02);
                }
            }
            else {
                window.alert("Geocode was not successful for the following reason: " + status);
            }
        };
        /**
         * @method moveMap マップとマーカーの移動
         **/
        this.moveMap = function (latLng) {
            var _a, _b;
            _this.latElement && (_this.latElement.value = latLng.lat().toString());
            _this.lngElement && (_this.lngElement.value = latLng.lng().toString());
            _this.position = latLng;
            (_a = _this.map) === null || _a === void 0 ? void 0 : _a.panTo(_this.position);
            (_b = _this.marker) === null || _b === void 0 ? void 0 : _b.setPosition(_this.position);
            var links = document.getElementsByClassName(mapConstants_1.CLASS_NAMES.LINK);
            for (var i = 0; i < links.length; i++) {
                var link = links[i];
                link instanceof HTMLAnchorElement && (link.href = mapConstants_1.MAP_BASE_URL.href + "&query=".concat(latLng.lat(), ",").concat(latLng.lng()));
            }
        };
        /**
         * @method geoCoding ジオコーディング  住所から緯度経度に変換する
         * @param { String } address 住所
         * @param  { boolean }  inputAddress アドレスを出力するか
         **/
        this.geoCoding = function (address, inputAddress) {
            var self = _this;
            var geoCodeRequest = {
                address: address,
                componentRestrictions: {
                    country: "JP",
                },
                language: "ja",
                region: "JP",
            };
            if (!_this.geocoder)
                return;
            _this.geocoder.geocode(geoCodeRequest, function (result, status) {
                self.moveMap(result[0].geometry.location);
                if (inputAddress !== true)
                    return;
                self.getAddress(result, status);
            });
        };
        /**
         * @method reverseGeoCoding リバースジオコーディング  緯度経度から住所に変換する
         *  @param {google.maps.LatLng}  latLng  緯度経度
         **/
        this.reverseGeoCoding = function (latLng) {
            var reverseGeoCodeRequest = { location: latLng };
            _this.moveMap(latLng);
            if (!_this.geocoder)
                return;
            _this.geocoder.geocode(reverseGeoCodeRequest, _this.setAddress);
        };
        this.currrentAddress = { postal_code: "", pref: "", addr01: "", addr02: "" };
        this.loader = new js_api_loader_1.Loader({
            apiKey: mapConstants_1.API_KEY,
            version: "weekly",
            region: "JP",
            language: "ja",
            libraries: ["geometry"],
            // something additional options
        });
        this.mapElement = document.querySelector(".js_map");
        this.latElement = document.querySelector([constants_1.ELEMENT_CLASS_PREFIX, mapConstants_1.MAP_TAGNAME_LAT].join(""));
        this.lngElement = document.querySelector([constants_1.ELEMENT_CLASS_PREFIX, mapConstants_1.MAP_TAGNAME_LNG].join(""));
        this.InputElements = [];
        this.postalCodeElement = document.querySelector([constants_1.ELEMENT_CLASS_PREFIX, mapConstants_1.MAP_TAGNAME_POSTAL_CODE].join(""));
        this.postalCodeElement != null ? this.InputElements.push(this.postalCodeElement) : "";
        this.prefElement = document.querySelector([constants_1.ELEMENT_CLASS_PREFIX, mapConstants_1.MAP_TAGNAME_PREF].join(""));
        this.prefElement != null ? this.InputElements.push(this.prefElement) : "";
        this.addr01Element = document.querySelector([constants_1.ELEMENT_CLASS_PREFIX, mapConstants_1.MAP_TAGNAME_ADDR_01].join(""));
        this.addr01Element != null ? this.InputElements.push(this.addr01Element) : "";
        this.addr02Element = document.querySelector([constants_1.ELEMENT_CLASS_PREFIX, mapConstants_1.MAP_TAGNAME_ADDR_02].join(""));
        this.addr02Element != null ? this.InputElements.push(this.addr02Element) : "";
        this.addressPattern = {
            Addr01: ["administrative_area_level_2", "locality", "sublocality_level_1", "sublocality_level_2"],
            Addr02: ["sublocality_level_3", "sublocality_level_4", "premise"],
        };
        this.mapOptions = {
            zoom: 14,
            mapTypeControl: false,
            streetViewControl: false,
        };
        this.loader
            .importLibrary("maps")
            .then(function (_a) {
            var _b, _c, _d, _e;
            var Map = _a.Map;
            if (_this.mapElement == null)
                return;
            _this.mapElement != null ? (_this.map = new Map(_this.mapElement, _this.mapOptions)) : "";
            _this.markerOptions = {
                clickable: true,
                map: _this.map,
                draggable: true,
            };
            _this.marker = new google.maps.Marker(_this.markerOptions);
            _this.geocoder = new google.maps.Geocoder();
            switch (true) {
                case _this.latElement && _this.latElement.value != "" && _this.lngElement && ((_b = _this.lngElement) === null || _b === void 0 ? void 0 : _b.value) != "":
                    if (_this.latElement && _this.latElement.value != "" && _this.lngElement && ((_c = _this.lngElement) === null || _c === void 0 ? void 0 : _c.value) != "") {
                        _this.position = {
                            lat: parseFloat(_this.latElement.value),
                            lng: parseFloat(_this.lngElement.value),
                        };
                        _this.map ? _this.map.setCenter(_this.position) : "";
                        _this.marker.setPosition(_this.position);
                    }
                    break;
                case _this.latElement && _this.latElement.value == "" && _this.addr01Element && _this.addr01Element.value != "":
                    var address_1 = "";
                    _this.InputElements.forEach(function (input) {
                        var value = "";
                        if (input != null && input === _this.postalCodeElement) {
                            value = input.value.substring(0, 2) + "-" + input.value.substring(3, 6);
                            _this.currrentAddress ? (_this.currrentAddress.postal_code = value) : "";
                        }
                        else if (input != null) {
                            switch (true) {
                                case input === _this.prefElement:
                                    _this.currrentAddress ? (_this.currrentAddress.pref = value) : "";
                                    break;
                                case input === _this.addr01Element:
                                    _this.currrentAddress ? (_this.currrentAddress.addr01 = value) : "";
                                    break;
                                case input === _this.addr02Element:
                                    _this.currrentAddress ? (_this.currrentAddress.addr02 = value) : "";
                                    break;
                            }
                            value = input.value;
                        }
                        address_1 += " " + value;
                    });
                    _this.geoCoding(address_1, false);
                    break;
                case navigator.geolocation != undefined:
                    navigator.geolocation.getCurrentPosition(function (position) {
                        var _a, _b;
                        _this.position = {
                            lat: position.coords.latitude,
                            lng: position.coords.longitude,
                        };
                        (_a = _this.map) === null || _a === void 0 ? void 0 : _a.setCenter(_this.position);
                        (_b = _this.marker) === null || _b === void 0 ? void 0 : _b.setPosition(_this.position);
                    }, function (err) {
                        var _a, _b;
                        _this.position = {
                            lat: 34.6863888888889,
                            lng: 135.51999999999998,
                        };
                        (_a = _this.map) === null || _a === void 0 ? void 0 : _a.setCenter(_this.position);
                        (_b = _this.marker) === null || _b === void 0 ? void 0 : _b.setPosition(_this.position);
                    }, { enableHighAccuracy: true });
                    break;
                default:
                    _this.position = {
                        lat: 34.6863888888889,
                        lng: 135.51999999999998,
                    };
                    (_d = _this.map) === null || _d === void 0 ? void 0 : _d.setCenter(_this.position);
                    _this.marker.setPosition(_this.position);
                    break;
            }
            _this.InputElements.forEach(function (element) {
                if (element == null)
                    return;
                _this.listenInput(element);
            });
            google.maps.event.addListener(_this.marker, "dragend", _this.dragPin);
            (_e = _this.map) === null || _e === void 0 ? void 0 : _e.addListener("click", _this.clickPosition);
        })
            .catch(function (e) {
            window.alert("Failed to Load Map." + e);
        });
    }
    /**
     * 住所入力でマップとマーカーを移動
     *  @param {HTMLInputElement | HTMLSelectElement} element 住所入力欄
     **/
    InitMap.prototype.listenInput = function (element) {
        element.addEventListener("keydown", this.moveMapWithAddress.bind(element, this), {
            capture: true,
            passive: false,
        });
        element.addEventListener("change", this.moveMapWithAddress.bind(element, this));
    };
    /**
     * @method moveMapWithAddress
     * @param {(HTMLInputElement | HTMLSelectElement)} this
     * @param {InitMap} handler
     * @param {(KeyboardEvent | Event)} e
     */
    InitMap.prototype.moveMapWithAddress = function (handler, e) {
        var _this = this;
        if (e instanceof KeyboardEvent && e.key !== "Enter")
            return;
        e instanceof KeyboardEvent && e.preventDefault();
        var address = "";
        setTimeout(function () {
            if ((!(e.target instanceof HTMLInputElement) && !(e.target instanceof HTMLSelectElement)) || e.target.value == "")
                return;
            var target = e.target;
            if ((target == handler.postalCodeElement && target.value !== "") || target == handler.prefElement) {
                var value_1 = target.value;
                if (e.target instanceof HTMLSelectElement) {
                    Array.from(e.target.options, function (option) {
                        if (option.value === value_1) {
                            return (value_1 = option.text);
                        }
                    });
                }
                handler.geoCoding(value_1, true);
            }
            else {
                handler.InputElements.forEach(function (input) {
                    var value = "";
                    if (input != null && input === handler.postalCodeElement) {
                        value = input.value.substring(0, 2) + "-" + input.value.substring(3, 6);
                        handler.currrentAddress ? (handler.currrentAddress.postal_code = value) : "";
                    }
                    else if (input != null) {
                        switch (true) {
                            case input === handler.prefElement:
                                handler.currrentAddress ? (handler.currrentAddress.pref = value) : "";
                                break;
                            case input === handler.addr01Element:
                                handler.currrentAddress ? (handler.currrentAddress.addr01 = value) : "";
                                break;
                            case input === handler.addr02Element:
                                handler.currrentAddress ? (handler.currrentAddress.addr02 = value) : "";
                                break;
                        }
                        value = input.value;
                    }
                    address += " " + value;
                });
                handler.geoCoding(address, false);
            }
            if (e instanceof KeyboardEvent) {
                var focusableElements = document.querySelectorAll("input, select, textarea");
                var currentIndex = Object.values(focusableElements).findIndex(function (value) { return _this === value; });
                if (focusableElements[currentIndex + 1] instanceof HTMLSelectElement) {
                    focusableElements[currentIndex + 2].focus();
                }
                else {
                    focusableElements[currentIndex + 1].focus();
                }
            }
        }, 100);
    };
    return InitMap;
}());
exports.InitMap = InitMap;
