"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ScrollLockFix = void 0;
var constants_1 = require("../constants");
/**
 * スクロール固定のイベント
 * @constructor element: HTMLElement
 * @function scrollLock event - Event 以下の手順で発生するスクロールのバグ対策。回避するため1pxだけスクロール量を減らす
 * 1. メニューを上下どちらかに最大までスクロールする
 * 1. 更にスクロールを行うとページ全体がスクロールする
 **/
var ScrollLockFix = /** @class */ (function () {
    /**
     * @constructor
     * @param {HTMLElement} element 対象の要素
     */
    function ScrollLockFix(element) {
        var _this = this;
        this.listen = function () {
            document.addEventListener("wheel", _this.wheelHandler, { capture: true, passive: false });
            document.addEventListener("touchmove", _this.touchHandler, { capture: true, passive: false });
        };
        this.unlisten = function () {
            document.removeEventListener("wheel", _this.wheelHandler, true);
            document.removeEventListener("touchmove", _this.touchHandler, true);
        };
        this.touchHandler = function (e) {
            if (!e.target || !(e.target instanceof HTMLElement))
                return;
            var target = e.target;
            var scrollableElement = target.classList.contains(constants_1.STATUS_CLASS_NAMES.CAN_SCROLL)
                ? target
                : target.closest("." + constants_1.STATUS_CLASS_NAMES.CAN_SCROLL);
            if (scrollableElement == null) {
                // console.log("対象の要素でなければスクロール禁止")
                return e.preventDefault();
            }
            if (scrollableElement.clientHeight <= scrollableElement.scrollHeight) {
                console.log(scrollableElement.scrollHeight);
                // console.log("対象の要素があり、その要素がスクロール可能であればスクロールを許可する")
                e.stopImmediatePropagation();
            }
            else {
                // console.log("対象の要素はスクロール禁止")
                e.preventDefault();
            }
        };
        this.wheelHandler = function (e) {
            if (!e.target || !(e.target instanceof HTMLElement))
                return;
            var target = e.target;
            var deltaY = e.deltaY;
            var scrollableElement = target.classList.contains(constants_1.STATUS_CLASS_NAMES.CAN_SCROLL)
                ? target
                : target.closest("." + constants_1.STATUS_CLASS_NAMES.CAN_SCROLL);
            if (scrollableElement != null) {
                if (scrollableElement.scrollHeight < 1 && (Math.sign(deltaY) === -1 || scrollableElement.scrollTop === 0)) {
                    e.preventDefault();
                }
                else {
                    e.stopPropagation();
                }
            }
            else {
                e.preventDefault();
            }
        };
        this.target = element;
        this.body = document.body;
    }
    return ScrollLockFix;
}());
exports.ScrollLockFix = ScrollLockFix;
