"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DROPDOWN_TEXT = exports.CLASS_NAMES = void 0;
exports.CLASS_NAMES = {
    PARENT: "js-dropdown",
    TARGET: "js-dropdownTarget",
    TRIGGER: "js-dropdownTrigger",
};
exports.DROPDOWN_TEXT = {
    OPEN: "",
    CLOSE: "閉じる",
};
