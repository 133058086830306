"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CSS_CAN_SCROLL = exports.MAP_TAGNAME_ADDR_02 = exports.MAP_TAGNAME_ADDR_01 = exports.MAP_TAGNAME_PREF = exports.MAP_TAGNAME_POSTAL_CODE = exports.MAP_TAGNAME_LNG = exports.MAP_TAGNAME_LAT = exports.API_KEY = exports.MAP_BASE_URL = exports.ID_NAMES = exports.CLASS_NAMES = exports.GEOCODER_RESULTS = void 0;
exports.GEOCODER_RESULTS = {
    TYPES: {
        STREET_ADDRESS: "street_address",
        ROUTE: "route",
        INTERSECTION: "intersection",
        POLITICAL: "political",
        PREF: "administrative_area_level_1",
        DISTRICT: "administrative_area_level_2",
        MUNICIPALITIES: "locality",
        SUBLOCALITY01: "sublocality_level_1",
        SUBLOCALITY02: "sublocality_level_2",
        SUBLOCALITY03: "sublocality_level_3",
        SUBLOCALITY04: "sublocality_level_4",
        SUBLOCALITY05: "sublocality_level_5",
        PREMISE: "premise",
        SUBPREMISE: "subpremise",
    },
};
exports.CLASS_NAMES = {
    MAP: "js-map",
    POSTAL_CODE_INPUT: "js-postalCode",
    PREF_INPUT: "js-pref",
    ADDR01_INPUT: "js-addr01",
    ADDR02_INPUT: "js-addr02",
    LINK: "js-mapLink",
};
exports.ID_NAMES = {
    FORM: "js-autofillAddress",
};
exports.MAP_BASE_URL = new URL("maps/search/?api=1", "https://www.google.com/");
/**
 * Google Map API で利用するAPI Key
 * @constant
 * @type {string}
 */
exports.API_KEY = "AIzaSyDFdYOWmEOBS8G84vSZlStVrK4YOUmSi-c";
/**
 * Google Map API で利用するTagName
 *  @const {[index: string]: string,}
 */
exports.MAP_TAGNAME_LAT = "js_map_lat", exports.MAP_TAGNAME_LNG = "js_map_lng", exports.MAP_TAGNAME_POSTAL_CODE = "js_map_postal_code", exports.MAP_TAGNAME_PREF = "js_map_pref", exports.MAP_TAGNAME_ADDR_01 = "js_map_addr01", exports.MAP_TAGNAME_ADDR_02 = "js_map_addr02";
/**
 * 住所検索回避用
 * @const string[]
 */
exports.CSS_CAN_SCROLL = "is-scrollable";
