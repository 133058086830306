"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.DialogListener = void 0;
var DialogHandler_1 = require("../Dialog/DialogHandler");
var DialogListener = /** @class */ (function (_super) {
    __extends(DialogListener, _super);
    function DialogListener(element) {
        var _this = _super.call(this, element) || this;
        /**
         * triggerを監視
         * タッチデバイスにはtouchstartイベントを、マウスカーソルを操作するPCデバイスにはclickイベントを使う
         */
        _this.listen = function () {
            if (!_this.trigger)
                return;
            _this.trigger.addEventListener("click", function (e) {
                _this.handler(e);
                /**
                 * Escapeの押下を監視
                 */
                document.addEventListener("keydown", _this.keyboardHandler, { capture: true });
            });
            _this.closableElements.length > 0 && _this.listenClosableElements();
        };
        return _this;
    }
    DialogListener.prototype.listenClosableElements = function (func) {
        var _this = this;
        this.closableElements.forEach(function (element) {
            element.addEventListener("click", function () {
                _this.close();
                func && func();
            });
        });
    };
    return DialogListener;
}(DialogHandler_1.DialogHandler));
exports.DialogListener = DialogListener;
