"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DrawerHandler = void 0;
var constants_1 = require("../constants");
var drawerConstants_1 = require("../constants/drawerConstants");
var DrawerHandler = /** @class */ (function () {
    function DrawerHandler(trigger) {
        this.trigger = trigger;
        this.isActive = false;
        this.controls = trigger.getAttribute("aria-controls");
        this.container = trigger.closest("." + drawerConstants_1.CLASS_NAMES.CONTAINER);
        this.target = this.container ? this.container.querySelector("." + drawerConstants_1.CLASS_NAMES.TARGET) : null;
    }
    DrawerHandler.prototype.handler = function (e) {
        if (e.cancelable) {
            e.preventDefault();
        }
        if (this.isActive) {
            this.close();
        }
        else {
            this.open();
        }
    };
    DrawerHandler.prototype.open = function () {
        this.isActive = true;
        this.trigger.ariaExpanded = "true";
        if (!this.target)
            return;
        this.target.classList.add(constants_1.STATUS_CLASS_NAMES.ACTIVE);
        this.target.ariaHidden = "false";
    };
    DrawerHandler.prototype.close = function () {
        this.isActive = false;
        this.trigger.ariaExpanded = "false";
        if (!this.target)
            return;
        this.target.classList.remove(constants_1.STATUS_CLASS_NAMES.ACTIVE);
        this.target.ariaHidden = "true";
    };
    return DrawerHandler;
}());
exports.DrawerHandler = DrawerHandler;
