"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.InitCustomersClient = void 0;
var InitCustomersClient = /** @class */ (function () {
    function InitCustomersClient() {
        this.isExistsinStorage = function (key) {
            var sessionStorageValue = window.sessionStorage.getItem(key), localStorage = window.localStorage.getItem(key), storages = [sessionStorageValue, localStorage];
            return storages.some(function (value) { return value !== null; });
        };
    }
    return InitCustomersClient;
}());
exports.InitCustomersClient = InitCustomersClient;
