"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.IDS = exports.COMBOBOX_CLASS_NAMES = void 0;
/**
 * Comboboxで使用するクラス名
 **/
exports.COMBOBOX_CLASS_NAMES = {
    PARENT: "js_combobox",
    INPUT: "js_combobox__input",
    DATALIST: "js_combobox__list",
    TARGET: "js_combobox__target",
    SET_VALUE: "js_combobox__setvalue",
    RESULT_LIST: "js_combobox__resultList",
};
exports.IDS = {
    COUNT: "comboboxCount",
};
