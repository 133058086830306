"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CLASS_NAMES = void 0;
/**
 * クラス名
 **/
exports.CLASS_NAMES = {
    CONTAINER: "js-drawer",
    TRIGGER: "js-drawerTrigger",
    TARGET: "js-drawerTaget",
};
