"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DropdownHandler = void 0;
var constants_1 = require("../constants");
var ariaAttributes_1 = require("../utils/ariaAttributes");
var DropdownHandler = /** @class */ (function () {
    function DropdownHandler() {
    }
    DropdownHandler.prototype.handler = function (handler, e) {
        if ((0, ariaAttributes_1.isAriaAttributeTrue)(this.trigger.getAttribute("aria-expanded")) ||
            this.trigger.classList.contains(constants_1.STATUS_CLASS_NAMES.ACTIVE)) {
            handler.close(this);
        }
        else {
            handler.open(this);
        }
    };
    /** 開く */
    DropdownHandler.prototype.open = function (dropdown) {
        dropdown.trigger.setAttribute("aria-expanded", "true");
        dropdown.trigger.classList.add(constants_1.STATUS_CLASS_NAMES.ACTIVE);
        if (dropdown.dataLabel) {
            this.replaceLabel(dropdown.trigger, dropdown.dataLabel);
        }
        dropdown.targets.forEach(function (target) {
            if (!target)
                return;
            target.classList.add(constants_1.STATUS_CLASS_NAMES.ACTIVE);
            if ((0, ariaAttributes_1.isAriaAttributeTrue)(target.getAttribute("aria-hidden"))) {
                target.setAttribute("aria-hidden", "false");
            }
            if (target instanceof HTMLDetailsElement) {
                target.toggleAttribute("open", true);
            }
        });
    };
    /** 閉じる */
    DropdownHandler.prototype.close = function (dropdown) {
        dropdown.trigger.setAttribute("aria-expanded", "false");
        dropdown.trigger.classList.remove(constants_1.STATUS_CLASS_NAMES.ACTIVE);
        if (dropdown.dataLabel) {
            this.replaceLabel(dropdown.trigger, dropdown.dataLabel);
        }
        dropdown.targets.forEach(function (target) {
            if (!target)
                return;
            target.classList.remove(constants_1.STATUS_CLASS_NAMES.ACTIVE);
            if (target.getAttribute("aria-hidden") === "false") {
                target.setAttribute("aria-hidden", "true");
            }
            if (target instanceof HTMLDetailsElement) {
                target.toggleAttribute("open", false);
            }
        });
    };
    /** テキストを変える
     */
    DropdownHandler.prototype.replaceLabel = function (trigger, newLabel) {
        var child = trigger.querySelector("[data-label]");
        var replaceElement = child || trigger;
        var innerText = child ? child.innerText : trigger.innerText;
        replaceElement.innerHTML = replaceElement.innerHTML.replace(innerText, newLabel);
        replaceElement.setAttribute("data-label", innerText);
        return;
    };
    return DropdownHandler;
}());
exports.DropdownHandler = DropdownHandler;
