"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CustomerClientEvent = void 0;
var constants_1 = require("../constants");
var customersClientConstants_1 = require("../constants/customersClientConstants");
var InitCustomersClient_1 = require("./InitCustomersClient");
var CustomerClientEvent = exports.CustomerClientEvent = /** @class */ (function () {
    function CustomerClientEvent() {
        var self = this;
        window.addEventListener("pageshow", function () {
            self.changeClientButton = this.document.querySelector(constants_1.PERFIX.CLASS_NAME + customersClientConstants_1.CUSTOMERSCLIENT_CLASSNAMES.CHANGE_BUTTON);
            if (new InitCustomersClient_1.InitCustomersClient().isExistsinStorage("customersClient") !== true)
                return;
        });
    }
    CustomerClientEvent.CLASS_NAMES = {
        TRIGGER: "js-changeCustomersClient",
    };
    return CustomerClientEvent;
}());
